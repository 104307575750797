.App-header {
  font-family: 'Cairo', sans-serif;
}

.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
}

.ReactModal__Overlay--after-open{
  transform: scale(1);
}

.ReactModal__Overlay--before-close{
  transform: scale(0);
}

.img-orga{
  width: 95vw;
  margin: auto;
}

@media (min-width: 600px) {
  .img-orga{
    width: 30vw;
  }
}