@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App-header {
  font-family: 'Cairo', sans-serif;
}

.ReactModal__Overlay {
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  transition-delay: 100ms;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.ReactModal__Overlay--after-open{
  -webkit-transform: scale(1);
          transform: scale(1);
}

.ReactModal__Overlay--before-close{
  -webkit-transform: scale(0);
          transform: scale(0);
}

.img-orga{
  width: 95vw;
  margin: auto;
}

@media (min-width: 600px) {
  .img-orga{
    width: 30vw;
  }
}
